require('./vendor/jquery.hoverIntent');

require('./vendor/air-datepicker/datepicker.min.js');
require('./vendor/air-datepicker/datepicker.en.js');
require('jquery-confirm');

require('./cookie.js');

var viewportWidth = window.innerWidth;

jQuery(document).ready(function ($) {

	viewportWidth = window.innerWidth;
	window.requestAnimationFrame(scrollHandler);
	window.requestAnimationFrame(resizeHandler);

	// hide success alert
	// if ( $('.alert-success').length ) {
	// 	setTimeout(function(){
	// 		$('.alert-success').slideUp();
	// 	}, 4000);
	// }

	//labels
	//$('.form_style :required').prev('label').addClass('required');

	// STYLED SELECT BOXES //
	// $('.form_style select, .styled_select').each(function(){
	// 	$(this).wrap('<div class="select_wrapper"></div>');
	// });

	$(".select_all input").on('change', function () {
		var what = $(this).data('select-all');

		$("input[name='"+what+"[]']").not(this).prop('checked', this.checked);

	});

	$('.frontend-datetimepicker').each(function () {
		front_dtp_el = $(this);

		var date = new Date();
		var startDate = front_dtp_el.data('startdate');


		if ( startDate !== undefined){
			var startDateParts = startDate.split("/");

			date = new Date(
				startDateParts[2],  // Year
				startDateParts[1] - 1,  // Month
				startDateParts[0]  // Day
			);

			date.setHours(startDateParts[3] || 0);
			date.setMinutes(startDateParts[4] || 0);
			date.setSeconds(0);
		}

		var minDate = new Date();
		var setMinDate = front_dtp_el.data('setmindate');
		if ( setMinDate !== undefined){
			var setMinDate = setMinDate.split("/");
			minDate = new Date(
				setMinDate[2],  // Year
				setMinDate[1] - 1,  // Month
				setMinDate[0]  // Day
			);
			minDate.setHours(0);
			minDate.setMinutes(0);
			minDate.setSeconds(0);
		}

		var front_dtp = front_dtp_el.datepicker({
			language: 'en',
			dateFormat: 'dd/mm/yyyy',
			timeFormat: 'hh:ii',
			todayButton: false,
			minDate: minDate,
			//autoClose: true,
			timepicker: true,
		}).data('datepicker');

		if ( startDate !== undefined){
			front_dtp.selectDate(date);
		}else{
			front_dtp.selectDate(minDate);
		}

	});


	$('.frontend-datepicker').each(function () {
		var date = new Date();
		var startDate = $(this).data('startdate');
		if (startDate !== undefined) {
            var setStartDate = startDate.split("/");
			startDate = new Date(
				setStartDate[2],  // Year
				setStartDate[1] - 1,  // Month
				setStartDate[0]  // Day
			);
		}
		var front_dp = $(this).datepicker({
			language: 'en',
			dateFormat: 'dd/mm/yyyy',
			todayButton: new Date(),
			maxDate: new Date(),
			autoClose: true,
		}).data('datepicker');

		if (startDate !== undefined) {
			front_dp.selectDate(date);
		}

	});


	$('#business_sector_select_all').on("click", function(event) {
	    if(this.checked) {
	        $('input[name="business_sector[]"]').each(function() {
	            this.checked = true;
	        });
	    } else {
	        $('input[name="business_sector[]"]').each(function() {
	            this.checked = false;
	        });
	    }
	});

	$('input[name="business_sector[]"]').on("click", function(event) {
		$('#business_sector_select_all').prop('checked', true);
        $('input[name="business_sector[]"]').each(function() {
        	if(!this.checked) {
            	$('#business_sector_select_all').prop('checked', false);
           	}
        });
	});

	if ( $('select.conditional_input').length ) {

		$( "select.conditional_input" ).change(function() {
			conditional_select(this);
		});

		conditional_select();

	}

	function conditional_select($element){

		if($element == undefined){
			$element = $('select.conditional_input');
		}

		var inputChange = $($element).data('input').split(",");
		var inputCheck = $($element).data('condition');
		var preselect = $($element).data('preselect');

        for(var i = 0; i < inputChange.length; i++){

        	var inputs = $( "select[name='"+ inputChange[i] +"'], input[name='"+ inputChange[i] +"']" );

	       	inputs.closest('div').hide();

			var selectOption = $($element).find('option:selected').val();
			if(selectOption == inputCheck){
				inputs.closest('div').show();
			}else{
				inputs.closest('div').hide();
				$( "select[name='"+ inputChange[i] +"']").val(preselect);
				$( "input[name='"+ inputChange[i] +"']").prop('checked', false);
				$( "input[name='"+ inputChange[i].replace("[]", "") +"']").val('');

			}

        }
	}

	if ( $('select.town_free_type_check').length ) {

		$( "select.town_free_type_check" ).change(function() {
			free_type_town_change(this);
		});

		free_type_town_change();

	}

	function free_type_town_change($element){

		if($element == undefined){
			$element = $('select.town_free_type_check');
		}

		var freeTypes = $($element).data('freetype');
		var selected = parseInt($($element).find('option:selected').val());

		if( freeTypes.includes(selected) ){
			$('.free_type_town[data-freetype="' + selected + '"]').show();
		}else{
			$('.free_type_town').hide()
		}

	}

	// Mobile Menu
	$('.header__right__menu__btn').on("click", function(){
		if( $('.header__left .menu').hasClass("open") ){
			$('.header__left .menu').slideUp(600);
			$('.header__left .menu').removeClass("open");
		} else {
			$('.header__left .menu').addClass("open");
			$('.header__left .menu').slideDown(600);
		}
	})

	// Mobile Menu - Sub menu Fix
	$('.header__left .menu li.menu-item-has-children > a').on("click", function(e){
		if( $(window).width() <= 782 ){
			if( $(this).hasClass("ready") ){
				$('.header__left .menu li.menu-item-has-children .sub-menu').slideUp(400);
			} else {
				e.preventDefault();
				if( $(this).parents(".sub-menu").siblings().hasClass("ready") ) {
					$(this).siblings('.sub-menu').slideDown(400);
					$(this).addClass("ready");
				} else {
					$('.header__left .menu li.menu-item-has-children .sub-menu').slideUp(400);
					$(this).siblings('.sub-menu').slideDown(400);
					$('.header__left .menu li.menu-item-has-children > a.ready').removeClass("ready");
					$(this).addClass("ready");
				}
			}
		}
	});

	// Auth Form Switcher
	if ( $('.auth__switcher').length ) {
		$('.auth__switcher li').on("click", function(){
			$('.auth__switcher li.selected').removeClass("selected");
			$(this).addClass("selected");

			form = $(this).attr("data-form");
			form_class = '.' + form;
			$('.role_field').hide();
			$(form_class).show();

			$('input[name="role_type"]').val(form);

		});
		var role = $('input[name="role_type"]').val();
		$('.auth__switcher li[data-form="' + role + '"]').trigger('click');
	}

	//add required labels
	$('form :required').prev('label').addClass('required');
	$('form select:required').parent().prev('label').addClass('required');

	// Message Reply
	$('.request__messages__block__tools .reply').on('click', function(){
		$(this).parent().siblings('.request__messages__block__reply').slideToggle(400);
	});

	// Confirm
    jconfirm.defaults = {
        draggable: false,
        useBootstrap: false,
        boxWidth: '50rem',
        type: 'primary',
        typeAnimated: false
    };

	$('#save_attendees').on("click", function (e) {
		e.preventDefault();
		var form = $(this).closest('form');
        $.alert({
            title: 'Great!',
            content: 'By selecting attendees you will close the request and users will no longer be able to view and respond to your event (we advise contacting attendees by email to confirm attendance and coming back to select attendees when you have enough responses or approximately one week before the event).',
            buttons: {
                Cancel: {
                    text: 'Cancel',
                    action: function () {

                    }
                },
                OK: {
                    text: 'Continue',
                    btnClass: 'btn-primary',
                    action: function () {
						form.submit();
                    }
                }
            }
        });
    });

	// DO NOT CHANGE - trigger function when window scrolls
	$(window).scroll(function () {
		window.requestAnimationFrame(scrollHandler);
	});

	// DO NOT CHANGE - trigger function when window resizes
	$(window).resize(function() {
		window.requestAnimationFrame(resizeHandler);
	});

	// DO NOT CHANGE - trigger function when mouse moves
	$(document).on('mousemove',function(e){
		xpos = e.clientX;
		ypos = e.clientY;
		window.requestAnimationFrame(mouseHandler);
    });

	function resizeHandler() {
		viewportWidth = window.innerWidth;
	}

});

function scrollHandler() {
	var topOfViewport = $(window).scrollTop();
	var bottomOfViewport = topOfViewport + $(window).height();
}


function mouseHandler() {
}
