import * as CookieConsent from 'vanilla-cookieconsent';
/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
CookieConsent.run({

	// root: 'body',
	// autoShow: true,
	// disablePageInteraction: true,
	// hideFromBots: true,
	// mode: 'opt-in',
	revision: 2,

	cookie: {
		name: 'cc_cookie',
		// domain: location.hostname,
		// path: '/',
		// sameSite: "Lax",
		// expiresAfterDays: 365,
	},

	// https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
	guiOptions: {
		consentModal: {
			layout: 'cloud inline',
			position: 'middle center',
			equalWeightButtons: true,
			flipButtons: false
		},
		preferencesModal: {
			layout: 'box',
			equalWeightButtons: true,
			flipButtons: false
		}
	},

	onFirstConsent: ({cookie}) => {
		// console.log('onFirstConsent fired',cookie);
	},

	onConsent: ({cookie}) => {
        // console.log('onConsent fired', cookie);
	},

	onChange: ({changedCategories, changedServices}) => {
		// console.log('onChange fired!', changedCategories, changedServices);
	},

	onModalReady: ({modalName}) => {
		// console.log('ready:', modalName);
	},

	onModalShow: ({modalName}) => {
		// console.log('visible:', modalName);
	},

	onModalHide: ({modalName}) => {
		// console.log('hidden:', modalName);
	},

	categories: {
		necessary: {
			enabled: true,  // this category is enabled by default
			readOnly: true  // this category cannot be disabled
		},
		analytics: {
			autoClear: {
				cookies: [
					// {
					// 	name: /^_ga/,   // regex: match all cookies starting with '_ga'
					// },
					// {
					// 	name: '_gid',   // string: exact cookie name
					// }
				]
			},

			// https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
			services: {
				// ga: {
				// 	label: 'Google Analytics',
				// 	onAccept: () => {},
				// 	onReject: () => {}
				// },
				// youtube: {
				// 	label: 'Youtube Embed',
				// 	onAccept: () => {},
				// 	onReject: () => {}
				// },
			}
		},
		advertising: {}
	},

	language: {
		default: 'en',
		translations: {
			en: {
				consentModal: {
					title: 'We use cookies',
					description: 'We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept All”, you consent to the use of ALL the cookies. However you may visit the settings to provide a controlled consent.',
					acceptAllBtn: 'Accept all',
					acceptNecessaryBtn: 'Reject all',
					showPreferencesBtn: 'Settings',
					// closeIconLabel: 'Reject all and close modal',
					footer: `
						<a href="/privacy-policy" target="_blank">Privacy Policy</a>
					`,
				},
				preferencesModal: {
					title: 'Manage cookie preferences',
					acceptAllBtn: 'Accept all',
					acceptNecessaryBtn: 'Reject all',
					savePreferencesBtn: 'Accept current selection',
					closeIconLabel: 'Close modal',
					serviceCounterLabel: 'Service|Services',
					sections: [
						{
							title: 'Your Privacy Choices',
							description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.`,
						},
						{
							title: 'Strictly Necessary',
							description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
							linkedCategory: 'necessary',
							cookieTable: {
								caption: '',
								headers: {
									col1: 'Cookie',
									col2: 'Domain',
									col3: 'Description',
									col4: 'Expiry'
								},
								body: [
                                    {
                                        col1: 'XSRF-TOKEN',
                                        col2: 'shoutout.teesvalleycareers.com',
                                        col3: 'This cookie is used for security purposes.',
                                        col4: '2 hours',
                                    },
                                    {
                                        col1: 'tees_valley_shoutout_portal_session',
                                        col2: 'shoutout.teesvalleycareers.com',
                                        col3: 'This cookie is used to identify your browsing session and authenticated user account',
                                        col4: '2 hours',
                                    }
								]
							}
						}
					]
				}
			}
		}
	}
});
